<template>
  <div @width="isCollapse ? '64px' : '200px' " @change="collapseClick">
    <!-- 这是左侧菜单-->

    <!--定义折叠项-->
    <div class="leftCollapse" @click="collapseClick()">|||</div>

    <!--
        1.background-color="#2C3E50"
        2.text-color="#fff" 文本颜色
        3.active-text-color="#4094ff"
        4.unique-opened=true 是否只打开一个菜单
        5.collapse  是否折叠
        6.collapse-transition 折叠时是否展现动画效果
        7.default-active  默认选中的菜单
        8.router 启动以path路径当做请求地址
     -->

    <el-menu background-color="lightyellow"
             text-color="black"
             active-text-color="#4094ff"
             unique-opened
             :collapse="isCollapse" :collapse-transition="isCollapseTransition" router :default-active="defaultActive">

      <!-- 定义一级菜单 -->
      <el-submenu :index="menu.menuId+''" v-for="menu in menuList" :key="menu.menuId">
        <!-- 定义一级菜单模版 -->
        <template slot="title">
          <!-- 定义左侧图标-->
          <i :class="menuIcon[menu.menuId]"></i>
          <!-- 定义菜单名称-->
          <span>{{ menu.name }}</span>
        </template>
        <!-- 定义二级菜单 -->
        <el-menu-item :index="childrenMenu.path" v-for="childrenMenu in menu.children" :key="childrenMenu.menuId"
                      @click="defaultActiveMenu(childrenMenu.path)">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{ childrenMenu.name }}</span>
          </template>
        </el-menu-item>
      </el-submenu>

    </el-menu>
  </div>
</template>

<script>
import {getBackStageMenu} from "@/api/backStage/system";
export default {
  name: "myAside",
  data() {
    return {
      menuList: [],
      menuIcon: {
        '1': 'iconfont iconuser',
        '3': 'iconfont iconshangpin',
        '5': 'iconfont iconicon--copy',
        '7': 'iconfont iconquanxian',
        '8': 'iconfont iconziyuan'
      },
      //定义是否折叠
      isCollapse: false,
      //是否展现折叠动态效果
      isCollapseTransition: true,
      //定义默认高亮
      defaultActive: ''
    }
  },
  created() {
    this.getMenuList()
    //设定模式选中按钮
    this.defaultActive = window.sessionStorage.getItem("activeMenu")
  },
  methods: {
    logout() {
      //1.删除session中的记录
      window.sessionStorage.clear()
      //2.要求跳转到用户登录页面
      this.$router.push('/login')
    },
    async getMenuList() {
      //1.查询左侧菜单
      const params = {};
      getBackStageMenu(params).then(res => {
        if (res.code == 0) {
          this.menuList = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));

      // let menuListParam = [
      //   {
      //     id: '1', name: '系统管理', children: [
      //       {id: '2', name: '字典管理', path: '/backStage/system/dictionary'},
      //       {id: '3', name: '配置管理', path: '/backStage/system/config'},
      //       {id: '4', name: '后台菜单管理', path: '/backStage/system/config'}
      //     ],
      //   },
      //   {
      //     id: '2', name: '权限管理', children: [
      //       {id: '2', name: '软件管理', path: '/backStage/system/dictionary'}
      //     ],
      //   }
      // ]
      // this.menuList = menuListParam
    },
    //设定左侧折叠展开效果
    collapseClick() {
      this.isCollapse = !this.isCollapse
      this.$emit('collapseClick', this.isCollapse);
    },
    defaultActiveMenu(activeMenu) {
      //为了实现返回之后的选中效果,应该将数据保存到第三方中sessionStory
      window.sessionStorage.setItem("activeMenu", activeMenu)
      this.defaultActive = activeMenu
    }
  }
}
</script>

<style lang="less" scoped>
.el-aside {
  background-color: white;

  .el-menu {
    border-right: none;
  }
}
</style>
