<template>
    <div>
        <el-container>
            <el-header height="70px" :class="isTop ? 'el-header-top' : 'el-header-on-top'" style="min-width: 950px">
                <div class="title">
                    <div class="title-child icon-area" style="line-height: 62px;color: black;font-size: 28px">
                        <router-link :to="{path : '/'}" style="background-color: rgba(255, 255, 255, 0.0);">
                            小鸡软考
                        </router-link>
                    </div>
                </div>
                <div class="navigation">
                    <div class="navigation-child">
                        <router-link :to="{path : '/tools'}">在线工具</router-link>
                    </div>
                    <!--          <div class="navigation-child">-->
                    <!--            <router-link :to="{path : '/comics'}">漫画小屋</router-link>-->
                    <!--          </div>-->
                    <!--          <div class="navigation-child">-->
                    <!--            <router-link :to="{path : '/video'}">动漫电影</router-link>-->
                    <!--          </div>-->
                    <!--          <div class="navigation-child">-->
                    <!--            <router-link :to="{path : '/novel'}">小说文学</router-link>-->
                    <!--          </div>-->
                    <!--          <div class="navigation-child">-->
                    <!--            <router-link :to="{path : '/software'}">软件下载</router-link>-->
                    <!--          </div>-->
                    <div class="navigation-child">
                        <router-link :to="{path : '/exam'}">学习练题</router-link>
                    </div>
                </div>
                <div class="user" v-show="!loginFlag">
                    <div class="user-child">
                        <el-link @click="login" :underline="false" style="font-size: 16px">登录</el-link>
                    </div>
                </div>
                <div class="userAvatar" v-show="loginFlag">
                    <div class="user-child-avatar">
                        <el-dropdown :hide-on-click="false" placement="bottom">
                            <el-avatar size="large" :src="circleUrl"></el-avatar>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="info">个人信息</el-dropdown-item>
                                <el-dropdown-item @click.native="examSetting">练题设置</el-dropdown-item>
                                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </el-header>
            <el-main style="margin: 70px 0 0 0;padding: 0">
                <router-view></router-view>
            </el-main>
            <myFooter></myFooter>
        </el-container>
        <el-dialog
            title="系统设置"
            :visible.sync="dialogVisible"
            width="30%">
            <el-form ref="form" label-width="100px">
                <el-form-item label="考试模式">
                    <el-radio-group v-model="examModel" :style="{'--colour': '#409EFF'}">
                        <el-radio :label="'1'" style="font-size: 16px">考试模式 <br>&nbsp;&nbsp;（不显示答案和解析）<br>&nbsp;&nbsp;（答题卡中可交卷、查看整体情况）
                        </el-radio>
                        <el-radio :label="'2'" style="margin-top: 20px">做题模式<br>&nbsp;&nbsp;（默认，答错时显示答案和解析）
                        </el-radio>
                        <br>
                        <el-radio :label="'3'" style="margin-top: 20px">背题模式<br>&nbsp;&nbsp;（直接显示答案和解析）</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="连续做对几次自动清除错题" label-width="100px">
                    <el-slider
                        v-model="wrongCount"
                        :min="2"
                        :max="10"
                        show-input>
                    </el-slider>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExamUserSetting">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    doExamUserSetting, getExamUserSetting
} from "@/api/frontStage/doExam";
import {LoginBox} from "@/router/user/login";
import {getUserByJwt} from "@/api/user/login";
import myFooter from "@/views/frontStage/system/footer"

export default {
    components: {
        myFooter
    },
    name: "index",
    data() {
        return {
            loginFlag: false,
            isTop: true,
            dialogVisible: false,
            examModel: false,
            wrongCount: false,
            circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
        }
    },
    methods: {
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            scrollTop > 0 ? this.isTop = false : this.isTop = true
        },
        login() {
            LoginBox.install();
        },
        logout() {
            this.loginFlag = false;
            window.localStorage.removeItem('token');
            this.$message.success("退出成功");
        },
        info() {
            this.$message.success("开发中");
        },
        examSetting(){
            this.dialogVisible = true;
        },
        setting() {
        },
        doExamUserSetting() {
            const data = {
                examModel: this.examModel,
                wrongCount: this.wrongCount
            }
            doExamUserSetting(data).then(res => {
                this.dialogVisible = false;
            })
        },
        async getExamUserSetting() {
            await getExamUserSetting().then(res => {
                this.examModel = res.data.examModel;
                this.wrongCount = res.data.wrongCount;
            })
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    created() {
        if (window.localStorage.getItem('token') !== null) {
            this.loginFlag = true;
            getUserByJwt('/user/getUserByJwt').then(res => {
                this.user = res.data;
                if (this.user.headPortraitUrl === null) {
                    this.user.headPortraitUrl = "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
                }else {
                    this.circleUrl = this.user.headPortraitUrl;
                }
            })
        } else {
            this.loginFlag = false;
        }
        this.getExamUserSetting();
    }
}
</script>

<style scoped>
.navigation {
    margin: 0 auto;
    position: absolute;
    height: 70px;
    left: 50%;
    /*min-width:780px ;*/
    min-width: 200px;
    transform: translate(-50%, 0);
}

.navigation-child {
    width: 130px;
    float: left;
    height: 70px;
    line-height: 70px;
}

.title {
    margin: 0 auto;
    position: absolute;
    height: 50px;
    left: 8%;
    color: rgb(115, 115, 115);
    transform: translate(-50%, 10%);
}

.title-child {
    width: 140px;
    float: left;
    height: 70px;
    line-height: 70px;
}

.user {
    margin: 0 auto;
    position: absolute;
    height: 70px;
    right: 8%;
    color: rgb(115, 115, 115);
    transform: translate(-50%, -5%);
}

.user-child {
    width: 70px;
    float: right;
    height: 70px;
    line-height: 70px;
}

.userAvatar {
    margin: 0 auto;
    position: absolute;
    height: 70px;
    right: 10%;
    color: rgb(115, 115, 115);
    transform: translate(-50%, 20%);
}

.userAvatar-child {
    width: 130px;
    float: left;
    height: 70px;
    line-height: 70px;
}


.icon-area > a {
    background-color: rgba(255, 255, 255, 0.0);
    background-color: white;
    text-decoration: none;
    color: black;
    margin: 0;
    padding: 0;
}

.icon-area > a:hover {
    color: dodgerblue;
}

.navigation-child > a {
    background-color: rgba(255, 255, 255, 0.0);
    text-decoration: none;
    color: rgb(115, 115, 115);
    margin: 0;
    padding: 0;
}

.navigation-child > a:hover {
    color: dodgerblue;
}

.el-header-on-top {
    z-index: 10;
    position: fixed;
    width: 100%;
    padding: 0;
    height: 70px;
    background-color: white;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1px);
    box-shadow: 18px 0 8px -3px black;
    transition: box-shadow 0.6s;
    text-align: center;
}

.el-header-on-top span {
    color: black;
    position: relative;
}

.el-header-top {
    z-index: 10;
    position: fixed;
    width: 100%;
    padding: 0;
    height: 70px;
    background-color: white;
    color: black;
    text-align: center;
}

.el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 70px;
}

.el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
}

.el-main {
    /*background-color: #E9EEF3;*/
    /*color: #333;*/
    /*text-align: center;*/
    height: 100%;
}

body > .el-container {
    margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}
</style>
