import Request from '@/utils/request';

// 获取菜单
export const getBackStageMenu = (params) => Request.get('/chick/menu/getBackStageMenu', { data: { ...params }, loading: false });
// 保存菜单
export const saveMenu = (params) => Request.post('/chick/menu/save', { data: { ...params }, loading: false });
// 更新菜单
export const updateMenu = (params) => Request.post('/chick/menu/update', { data: { ...params }, loading: false });
// 删除菜单
export const removeMenu = (params) => Request.post('/chick/menu/removeByDataNum', { data: { ...params }, loading: false });

// 获取资源
export const getResource = (params) => Request.get('/chick/menu/getResource', { data: { ...params }, loading: false });
// 保存资源
export const saveResource = (params) => Request.post('/chick/menu/saveResource', { data: { ...params }, loading: false });
// 更新资源
export const updateResource = (params) => Request.post('/chick/menu/updateResource', { data: { ...params }, loading: false });
// 删除资源
export const removeResource = (params) => Request.post('/chick/menu/removeResource', { data: { ...params }, loading: false });

// 获取角色
export const getRole = (params) => Request.get('/chick/role/getRole', { data: { ...params }, loading: false });
// 保存角色
export const saveRole = (params) => Request.post('/chick/role/saveRole', { data: { ...params }, loading: false });
// 更新角色
export const updateRole = (params) => Request.post('/chick/role/updateRole', { data: { ...params }, loading: false});
// 删除角色
export const removeRole = (params) => Request.post('/chick/role/removeRole', { data: { ...params }, loading: false });

// 获取授权角色
export const getRoleMenu = (params) => Request.get('/chick/roleMenu/getRoleMenu', { data: { ...params }, loading: false });
// 通过角色id获取角色权限
export const getRoleMenuByRoleId = (params) => Request.get('/chick/roleMenu/getRoleMenuByRoleId', { data: { ...params }, loading: false });
// 授权
export const updateRoleMenu = (params) => Request.post('/chick/roleMenu/updateRoleMenu', { data: { ...params }, loading: false});

