<template>
  <div class="container-div">
    <el-container>
      <!--头-->
      <el-header>
        <Header></Header>
      </el-header>

      <el-container>
        <!--左侧边栏-->
        <el-aside :width="isCollapse ? '64px' : '200px'">
          <Aside @collapseClick="getIsCollapse"></Aside>
        </el-aside>

        <el-container>
          <!--内容-->
          <el-main>
            <router-view/>
          </el-main>

          <!--底部-->
          <el-footer>Footer</el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from '@/views/backStage/system/header'
import Aside from '@/views/backStage/system/aside'
export default {
  name: "myHeader",
  data() {
    return {
      //定义是否折叠
      isCollapse: false,
      //是否展现折叠动态效果
      isCollapseTransition: true,
      //定义默认高亮
      defaultActive: ''
    }
  },
  methods: {
    getIsCollapse(isCollapse){
      this.isCollapse = isCollapse;
    }
  },
  components: {
    Header,
    Aside
  }
}
</script>

<style scoped>
.container-div {
  height: 100vh;
  max-height: 100%;
}
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding: 0;
}

.el-aside {
  background-color: white;
  color: #333;
  text-align: center;
}

.el-main {
  padding: 0;
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.container-div > .el-container {
  height: 100%;
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  height: 100%;
}

.el-container:nth-child(7) .el-aside {
  height: 100%;
}
</style>
