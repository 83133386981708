<template>
  <div class="footer">
    <div class="content">
      <div style="float: left;padding: 20px 0 0 0">
        <span style="display: inline-block;width: 100px;height: 100%;line-height: 52px;vertical-align: middle;">微信公众号：</span>
        <el-image
            style="width: 110px; height: 110px;vertical-align: middle;"
            :src="require('@/assets/gongzhonghao.png')"
            fit="fit"></el-image>
      </div>
      <div style="float: left;padding: 20px 0 0 0">
        <span style="display: inline-block;width: 100px;height: 100%;line-height: 52px;vertical-align: middle;">刷题小程序：</span>
        <el-image
            style="width: 110px; height: 110px;vertical-align: middle;"
            :src="require('@/assets/xiaochengxu.png')"
            fit="fit"></el-image>
      </div>
<!--      <div style="float: left;padding: 20px 0 0 0">-->
<!--        <span style="display: inline-block;width: 100px;height: 100%;line-height: 52px;vertical-align: middle;">个人联系方式：</span>-->
<!--        <el-image-->
<!--            style="width: 110px; height: 110px;vertical-align: middle;"-->
<!--            :src="require('@/assets/qq.jpg')"-->
<!--            fit="fit"></el-image>-->
<!--      </div>-->
<!--      <div style="float: left">-->
<!--        <el-image-->
<!--            style="width: 150px; height: 150px"-->
<!--            :src="require('@/assets/wechat.jpg')"-->
<!--            fit="fit"></el-image>-->
<!--      </div>-->
      <div style="float: left">
      <span style="display: inline-block;width: 80px;height: 100%;line-height: 52px;vertical-align: middle;">QQ交流群：</span>
        <el-image
            style="width: 150px; height: 150px;vertical-align: middle;"
            :src="require('@/assets/qun.jpg')"
            fit="fit"></el-image>
      </div>
      <div style="display: inline-block;width: 250px;height: 100%;line-height: 52px;vertical-align: middle;">
        <a href="https://beian.miit.gov.cn/" class="filing">黑ICP备20000673号</a><br>
        © 2022 XiaoKeXin. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.footer {
  background-image: linear-gradient(to bottom, white, rgb(243, 244, 247));
  height: 100%;
  width: 100%;
}

.content {
  margin: 40px auto 0 auto;
  height: 100%;
  width: 920px;
  font-size: 13px;
  text-align: center;
}

.filing {
  text-decoration: none;
  color: rgb(115, 115, 115);
  margin: 0;
  padding: 0;
}
</style>
