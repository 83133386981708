<template>
  <div class="chick-header">
<!--    <img src="@/assets/logo.png" style="width:100px;height:100px;"/>-->
    <span>后台</span>
  </div>
</template>

<script>
export default {
  name: "myHeader",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.chick-header {
  background-color: #2B4B6B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  padding-left: 1px;
  margin: 0;
  > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
      font-size: 25px;
    }

  }
}

</style>
